import {
  ComponentType,
  MouseEventHandler,
  SVGProps,
  ReactElement,
} from 'react';
import { Button, Typography, ButtonProps } from '@ds';
import { SearchIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

export interface EmptyStateProps {
  HeaderIcon?: (props: SVGProps<SVGSVGElement>) => ReactElement | null;
  className?: string;
  description?: string;
  onClickPrimaryButton?: MouseEventHandler<HTMLButtonElement>;
  onClickSecondaryButton?: MouseEventHandler<HTMLButtonElement>;
  primaryButtonProps?: Pick<ButtonProps, 'LeadingIcon' | 'TrailingIcon'>;
  primaryButtonText?: string;
  secondaryButtonProps?: Pick<ButtonProps, 'LeadingIcon' | 'TrailingIcon'>;
  secondaryButtonText?: string;
  title?: string;
}

export const EmptyState: ComponentType<EmptyStateProps> = ({
  HeaderIcon,
  className,
  description,
  onClickPrimaryButton,
  onClickSecondaryButton,
  primaryButtonProps,
  primaryButtonText,
  secondaryButtonProps,
  secondaryButtonText,
  title,
}) => {
  return (
    <div className={clsx(`flex flex-col items-center`, className)}>
      <div className="bg-fresh-neon-100 border-fresh-neon-25 flex h-14 w-14 items-center justify-center rounded-full border-8">
        {HeaderIcon ? (
          <HeaderIcon className="text-fresh-neon-900 h-6 w-6" />
        ) : (
          <SearchIcon className="text-fresh-neon-900 h-6 w-6" />
        )}
      </div>
      {title && (
        <Typography
          className="mt-5 text-center text-gray-900"
          variant="text-heading-md"
        >
          {title}
        </Typography>
      )}
      <Typography
        className="mt-1 whitespace-pre-line text-center text-gray-500"
        variant="text-body-md"
      >
        {description ??
          'Your search or filter did not match any results.\nPlease try again.'}
      </Typography>
      <div className="mt-8 flex flex-col gap-3 sm:flex-row-reverse sm:items-center sm:justify-center">
        {!!onClickPrimaryButton && (
          <Button
            {...primaryButtonProps}
            className="w-full"
            size="sm"
            onClick={onClickPrimaryButton}
          >
            {primaryButtonText}
          </Button>
        )}
        {!!onClickSecondaryButton && (
          <Button
            {...secondaryButtonProps}
            className="w-full"
            size="sm"
            variant="secondary-gray"
            onClick={onClickSecondaryButton}
          >
            {secondaryButtonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EmptyState;
