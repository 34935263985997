import { ComponentType } from 'react';
import { Switch } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import Typography from '@/components/ui/typography';

type ToggleLabel = {
  description: string;
  title: string;
};

export interface ToggleProps {
  checked: boolean;
  disabled?: boolean;
  enableInnerTickIcon?: boolean;
  id?: string;
  label?: ToggleLabel;
  onChange(checked: boolean): void;
  size?: 'sm' | 'md';
}

export const Toggle: ComponentType<ToggleProps> = ({
  checked,
  disabled = false,
  enableInnerTickIcon = false,
  id,
  label,
  onChange,
  size = 'md',
}) => {
  return (
    <div className="flex items-start gap-2">
      <Switch
        checked={checked}
        className={clsx(
          'focus:ring-fresh-neon-100 group inline-flex items-center rounded-full outline-none transition duration-200 ease-linear focus:ring-1 disabled:bg-gray-100',
          checked ? 'bg-amplify-green-700' : 'bg-gray-100 hover:bg-gray-200',
          size === 'sm' && 'h-5 w-9',
          size === 'md' && 'h-6 w-11'
        )}
        disabled={disabled}
        id={id && id}
        onChange={onChange}
      >
        <span
          className={clsx(
            'inline-block rounded-full bg-white transition duration-200 ease-linear group-disabled:bg-gray-50',
            size === 'sm' && !checked && 'h-4 w-4 translate-x-0.5',
            size === 'sm' && checked && 'h-4 w-4 translate-x-[18px]',
            size === 'md' && !checked && 'h-5 w-5 translate-x-0.5',
            size === 'md' && checked && 'h-5 w-5 translate-x-[22px]'
          )}
        >
          {enableInnerTickIcon ? (
            <CheckIcon
              className={clsx(
                checked ? 'text-amplify-green-700' : 'text-gray-200'
              )}
            />
          ) : null}
        </span>
      </Switch>
      {!!label && (
        <div className="min-w-0 flex-1">
          <Typography
            className="text-gray-800"
            variant={size === 'sm' ? 'text-button-sm' : 'text-button-md'}
          >
            {label.title}
          </Typography>
          <Typography
            className="text-gray-600"
            variant={size === 'sm' ? 'text-body-sm' : 'text-body-md'}
          >
            {label.description}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default Toggle;
