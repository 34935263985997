import { ComponentType, HTMLAttributeAnchorTarget, useMemo } from 'react';
import clsx from 'clsx';
import NextLink from 'next/link';
import Typography, { TypographyVariant } from '@/components/ui/typography';

export interface LinkProps {
  children?: React.ReactNode;
  className?: string;
  href: string;
  size?: 'sm' | 'md' | 'lg' | 'caption';
  target?: HTMLAttributeAnchorTarget;
  variant?: 'primary' | 'secondary-gray' | 'white';
}

export const Link: ComponentType<LinkProps> = ({
  children,
  className,
  href,
  size = 'md',
  target = '_self',
  variant = 'primary',
}) => {
  const typographyVariant = useMemo<TypographyVariant>(() => {
    switch (size) {
      case 'caption':
        return 'text-hyperlink-caption';
      case 'lg':
        return 'text-hyperlink-lg';
      case 'sm':
        return 'text-hyperlink-sm';
      default:
        return 'text-hyperlink-md';
    }
  }, [size]);

  return (
    <NextLink href={href} target={target}>
      <Typography
        className={clsx(
          variant === 'primary' &&
            'text-amplify-green-700 hover:text-amplify-green-800 active:text-amplify-green-900 visited:text-gray-600',
          variant === 'secondary-gray' &&
            'hover:text-amplify-green-800 active:text-amplify-green-900 text-gray-900 visited:text-gray-600',
          variant === 'white' &&
            'text-gray-25 visited:text-fresh-neon-50 hover:text-gray-100 active:text-gray-100',
          className
        )}
        component="span"
        variant={typographyVariant}
      >
        {children}
      </Typography>
    </NextLink>
  );
};

export default Link;
