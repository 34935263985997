const MILLISECONDS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;

// Given a specific TimeZone, return the offset in minutes from UTC
// You can then figure out the timezone offset between two timezones by subtracting the two offsets
export const getTimeZoneOffsetForTimeZone = (date: Date, timeZone?: string) => {
  // Exploit the Intl API to get a local ISO 8601 string for a given time zone and include the milliseconds from the original date
  const iso =
    date
      .toLocaleString('en-CA', { hour12: false, timeZone })
      .replace(', ', 'T') +
    '.' +
    date.getMilliseconds().toString().padStart(3, '0');

  // Lie to the Date object constructor that it's a UTC time
  const lie = new Date(iso + 'Z');

  // Return the difference in timestamps, as minutes
  // For ease, postive values are east of GMT e.g. # 60 == GMT+1; -660 == GMT-11
  // This is opposite to the output of Date.getTimeZoneOffset()
  return (Number(lie) - Number(date)) / 60 / 1000;
};

export const getOffsettedDateObject = (
  date: Date,
  userTimeZoneOffset?: number
) => {
  if (userTimeZoneOffset) {
    // use offset to convert to the company's timeZone e.g. Europe/London, etc.
    const newDate = new Date(
      date.getTime() -
        userTimeZoneOffset * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND
    )
      .toLocaleString('en-CA', { hour12: false })
      .replace(', ', 'T');

    return new Date(newDate);
  }
  return date;
};

// Returns the current timeZone in effect for the timeZone/region given
// E.g. timeZone = 'Europe/London' may output 'British Summer Time' or 'Greenwich Mean Time' depending on the current time of the year
export const getRegionEffectiveTimeZone = (timeZone?: string) => {
  const date = new Intl.DateTimeFormat('en-US', {
    timeZone: timeZone,
    timeZoneName: 'long',
  }).formatToParts(new Date());
  return date.find((ele) => ele.type === 'timeZoneName')?.value;
};

export const getGMTFromTimezone = (timezone: string) => {
  try {
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      timeZoneName: 'longOffset',
    });

    const parts = formatter.formatToParts(new Date());
    const offset = parts.find((part) => part.type === 'timeZoneName')?.value;
    return offset?.replace('UTC', 'GMT'); // Replace 'UTC' with 'GMT' if necessary
  } catch (error) {
    return '';
  }
};
