import React, { useState, Fragment } from 'react';
import { Combobox as HeadlessCombobox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { Typography } from '@/components/ui/typography';

interface Option {
  label: string;
  value: string | number;
}

interface ComboboxProps {
  disabled?: boolean;
  error?: string;
  label?: string;
  onChange: (value: string | number) => void;
  options: Option[];
  placeholder?: string;
  size?: 'sm' | 'md' | 'lg';
  value: string | number | null;
}

export const Combobox: React.FC<ComboboxProps> = ({
  disabled = false,
  error,
  label,
  onChange,
  options,
  placeholder = 'Select an option',
  size = 'md',
  value,
}) => {
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) =>
          option.label.toLowerCase().includes(query.toLowerCase())
        );

  const sizeClasses = {
    lg: {
      button: 'py-3 pr-12 pl-4 text-lg',
      options: 'py-2 text-lg',
    },
    md: {
      button: 'py-2 pr-10 pl-4 text-base',
      options: 'py-1.5 text-base',
    },
    sm: {
      button: 'py-[7px] pr-8 pl-3 text-sm',
      options: 'py-1 text-sm',
    },
  };

  const selectedOption =
    options.find((option) => option.value === value) || null;

  return (
    <HeadlessCombobox
      disabled={disabled}
      value={selectedOption}
      onChange={(option: Option) => onChange(option.value)}
    >
      {({ open }) => (
        <div>
          {label && (
            <HeadlessCombobox.Label className="block">
              <Typography component="span" variant="text-button-sm">
                {label}
              </Typography>
            </HeadlessCombobox.Label>
          )}

          <div className={clsx({ 'mt-1': !!label, relative: true })}>
            <div className="relative w-full cursor-default">
              <HeadlessCombobox.Input
                className={clsx(
                  'w-full rounded-md border border-secondary-grey-dark bg-white font-normal text-text-main shadow focus:border-secondary-grey-dark focus:outline-none',
                  error && 'border-red-500',
                  sizeClasses[size].button
                )}
                displayValue={(option: Option) => option?.label}
                placeholder={placeholder}
                onChange={(event) => setQuery(event.target.value)}
              />
              <HeadlessCombobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon
                  aria-hidden="true"
                  className={clsx(
                    'h-5 w-5 text-gray-400',
                    error && 'text-red-500'
                  )}
                />
              </HeadlessCombobox.Button>
            </div>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              show={open}
            >
              <HeadlessCombobox.Options
                className={clsx(
                  'absolute z-20 mt-1 max-h-60 w-full cursor-pointer overflow-auto rounded-md bg-white font-normal shadow-lg ring-1 ring-black/5 focus:outline-none',
                  sizeClasses[size].options
                )}
              >
                {filteredOptions.length === 0 && query !== '' ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredOptions.map((option) => (
                    <HeadlessCombobox.Option
                      key={option.value}
                      className={({ active, selected }) =>
                        clsx(
                          'relative cursor-default select-none py-2 pl-3 pr-9 text-text-main',
                          active && 'bg-gray-200',
                          selected && 'bg-gray-100'
                        )
                      }
                      value={option}
                    >
                      {({ active, selected }) => (
                        <>
                          <span
                            className={clsx(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {option.label}
                          </span>
                          {selected && (
                            <span
                              className={clsx(
                                active ? '' : 'text-primary-green-dark',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon
                                aria-hidden="true"
                                className="size-5"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </HeadlessCombobox.Option>
                  ))
                )}
              </HeadlessCombobox.Options>
            </Transition>
          </div>
          {error && (
            <Typography
              className="text-red-500"
              component="span"
              variant="text-label-sm"
            >
              {error}
            </Typography>
          )}
        </div>
      )}
    </HeadlessCombobox>
  );
};
