// DS V2
import { useCallback, useState } from 'react';
import analytics from '@analytics';
import clsx from 'clsx';
import Button from '@/components/ui/button';
import Square2Stack from '@/components/ui/icons/square-2-stack';
import Typography from '@/components/ui/typography';
import { copyToClipboard } from '@/utils/common-helpers';

interface Props {
  className?: string;
  copyText?: string;
  hideUrl?: boolean;
  onCopyTrackingEvent?: string;
  url: string;
}

export const CopyLink: React.FC<Props> = ({
  className = 'overflow-auto whitespace-nowrap',
  copyText = 'Copy',
  hideUrl = false,
  onCopyTrackingEvent,
  url,
}) => {
  const [isCopied, setCopied] = useState(false);

  const onCopy = useCallback(async () => {
    if (onCopyTrackingEvent) {
      analytics.track(onCopyTrackingEvent);
    }
    await copyToClipboard(url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, [url, onCopyTrackingEvent]);

  return (
    <div
      className={clsx('', {
        '': hideUrl,
        'mt-1 flex h-10 items-center justify-between gap-3 rounded-lg border pl-2 pr-3':
          !hideUrl,
      })}
    >
      {!hideUrl && (
        <Typography
          className={clsx(className, {
            'text-gray-500': !hideUrl,
          })}
          variant="text-body-md"
        >
          {url}
        </Typography>
      )}
      <Button
        LeadingIcon={
          isCopied
            ? undefined
            : () => <Square2Stack className="h-4 w-4 text-gray-700" />
        }
        variant="link-gray"
        onClick={isCopied ? undefined : onCopy}
      >
        {isCopied ? 'Copied' : copyText}
      </Button>
    </div>
  );
};

export default CopyLink;
