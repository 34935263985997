import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import capitalize from 'lodash/capitalize';
import numeral from 'numeral';
import { COMMON_MOVEMENT_TYPES } from '@/utils/constants';

dayjs.extend(duration);

type FormatMoneyOptions = {
  format?: string; // numeral.js format string
};

function copyToClipboardLegacy(text: string) {
  const textarea = document.createElement('textarea');
  textarea.textContent = text;
  document.body.appendChild(textarea);
  textarea.select();
  const selection = document.getSelection();
  const range = document.createRange();
  range.selectNode(textarea);
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand('copy');
    selection.removeAllRanges();
  }
  document.body.removeChild(textarea);
}

function getCurrencySymbol(currency: string): string {
  switch (currency) {
    case 'GBP':
      return '£';
    case 'AUD':
      return '$';
    case 'GBX':
    default:
      return '';
  }
}

export async function copyToClipboard(text: string) {
  try {
    const result = await navigator.permissions.query({
      name: 'clipboard-write' as PermissionName,
    });

    const successfulStates: PermissionState[] = ['granted', 'prompt'];

    if (successfulStates.includes(result.state)) {
      navigator.clipboard.writeText(text);
    } else {
      copyToClipboardLegacy(text);
    }
  } catch {
    copyToClipboardLegacy(text);
  }
}

export function convertToPascalCase(label: string | null | undefined) {
  // Convert snake_case to Pascal Case
  //  remove _ and capitalize first letter
  if (!label) return '';
  return label
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());
}

export function convertToKebabCase(label: string | null | undefined) {
  // Convert any text to kebab-case
  //  remove ' ' and _ and lowercase
  if (!label) return '';
  return label
    .replace(/_/g, '-')
    .replace(/\s|\.+/g, '-')
    .replace(/\[|\]/g, '')
    .toLowerCase();
}

export function formatAverageHoldingTime(input: number) {
  const duration = dayjs.duration(input, 'days');
  const format = duration.asYears() >= 1 ? 'Y[yr] M[mth]' : 'M[mth] D[d]';

  return duration.format(format);
}

/**
 * Formats a number as a currency string.
 *
 * @param input - The amount of money to format.
 * @param currency - The currency code (e.g., 'GBP', 'AUD', 'GBX').
 * @param opts - Optional formatting options.
 * @returns The formatted money string.
 */
export function formatMoney(
  input: number,
  currency: string,
  opts?: FormatMoneyOptions
): string {
  const format = opts?.format ?? '0,0';

  if (currency === 'GBX') {
    return `${getCurrencySymbol('GBP')}${numeral(input / 100).format(format)}`;
  }

  return `${getCurrencySymbol(currency)}${numeral(input).format(format)}`;
}

export function formatMovementType(
  input?: string | null,
  movement?: number | null
): 'Bought' | 'Sold' | 'Other' | string {
  const cleanedInput = input && input.trim();

  if (cleanedInput) {
    if (COMMON_MOVEMENT_TYPES.includes(cleanedInput)) {
      if (typeof movement === 'number') {
        if (movement > 0) {
          return 'Bought';
        }

        if (movement < 0) {
          return 'Sold';
        }
      }

      return 'Other';
    }

    const inputTokens = cleanedInput.split(' ');
    const hasSpaces = inputTokens.length > 1;

    if (hasSpaces) {
      return inputTokens.map((it) => capitalize(it)).join(' ');
    }

    return cleanedInput;
  }

  return 'Other';
}

export function formatSocialPlatform(input: 'linkedin' | 'twitter') {
  switch (input) {
    case 'linkedin':
      return 'LinkedIn';
    default:
      return capitalize(input);
  }
}

/**
 * Toggles an item in a list based on a unique key.
 *
 * @param prev - The previous list of items.
 * @param item - The item to toggle.
 * @param key - The unique key to identify the item (e.g., 'id').
 * @returns A new list with the item added or removed.
 */
export const toggleItemInList = <T, K extends keyof T>(
  prev: T[],
  item: T,
  key: K
): T[] => {
  const exists = prev.some((element) => element[key] === item[key]);
  if (exists) {
    return prev.filter((element) => element[key] !== item[key]);
  } else {
    return [...prev, item];
  }
};
